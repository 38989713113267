import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PageHeading from "../components/PageHeading"
import TestimonialsCarousel from "../components/TestimonialsCarousel"
import CarouselSlide from "../components/CarouselSlide"
import FeaturedContent from "../components/FeaturedContent"
import "../assets/sass/style.scss"

const Journalism = ({ props }) => {
  return (
    <Layout>
      <SEO title="Fitness Journalism" description="Laura is an established fitness journalist, currently writing for titles including WW magazine, Vitality Digital and NetDoctor." />

      <PageHeading text="Journalism"></PageHeading>

      <FeaturedContent
        style={{ paddingTop: `0` }}
        cssClass="featured-content pt-0 bg-grey"
        heading="Journalism"
        imageSrc="about_laura@2x.jpg"
        imageAlt="Laura Williams Journalist"
        btnAction="/contact"
        btnLabel="Get in Touch"
      >
        <p>
          <strong>
            Laura is an established fitness journalist, currently writing for
            titles including WW magazine, Vitality Digital and NetDoctor.
          </strong>
        </p>
        <p>
          <strong>
            She has also written regularly for City A.M., Woman &amp; Home and
            HuffPost UK.
          </strong>
        </p>
      </FeaturedContent>

      <TestimonialsCarousel className="bg-yellow">
        <CarouselSlide author="Claire Spreadbury. Head of Features, PA Media">
          Laura Williams is a joy to work with when it comes to creating any
          fitness features. She has great ideas, can provide thoughtful expert
          comment and has worked with us to create some great, original videos.
        </CarouselSlide>
        <CarouselSlide author="Rhalou Allerhand. Digital Editor, NetDoctor">
          Laura is an efficient, consistent contributor and she really knows her
          subject. She writes accessible and relatable fitness content that
          appeals to a range of readers, and her no-nonsense, honest advice is
          enough to get anyone off the sofa and into their trainers.
        </CarouselSlide>
      </TestimonialsCarousel>
    </Layout>
  )
}

export default Journalism
